import React from 'react';
import { BlogLayout } from '../templates/BlogLayout';
import Image from 'gatsby-image'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby';
import NewsletterSignup, { CenteredNewsLetterSignup } from '../components/news_letter_signup';

export default () => {
  const data = useStaticQuery(graphql`
          query {
              file (relativePath: {eq: "yanns_face.jpg"}){
                  id,
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
          }
  `)
  return <BlogLayout>
    <Image
      css={css`
        width: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      `}
      fluid={data.file.childImageSharp.fluid}
    >

    </Image>
    <h3 css={css`
    text-align: center;
  `}>
      I look serious because this is a serious blog
    </h3>
    <CenteredNewsLetterSignup>
      <NewsletterSignup/>
    </CenteredNewsLetterSignup>
  </BlogLayout>
}
